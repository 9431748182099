<template lang="pug">
.brand-wrapper.became-ambassador
  .container
    .row
      .col-lg-6.col-12
        om-heading.pb-6(h1)
          span(v-html="$t('ambassadorAffiliate.heading')")
        om-body-text.became-ambassador-desc.pb-3(bt400lg)
          span(v-html="$t('ambassadorAffiliate.description')")
        .row
          .col-auto.py-3
            om-button(primary large @click="makeAmbassador") {{ $t('ambassadorAffiliate.cta') }}
          .col-auto.py-3
            om-button(secondary large @click="goToLanding") {{ $t('ambassadorAffiliate.learnMore') }}
        .d-flex
          om-body-text.became-ambassador-after(bt400xs)
            span(v-html="$t('ambassadorAffiliate.byClicking')")
      .col-lg-6.col-12.d-lg-flex.d-none
        img(src="@/assets/admin/svg/ambassador-commission.svg")
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        ambassadorError:
          'Error happened while switching to ambassador account. Please contact Support, or try again later.',
      };
    },
    computed: {
      ...mapGetters(['isAffiliate']),
    },

    async created() {
      const query = this.$route.query;
      if (query?.auto) {
        await this.makeAmbassador();
      }
    },

    methods: {
      ...mapActions(['fetchAccount']),
      goToLanding() {
        window.open('https://www.optimonk.com/ambassador-partner/');
      },
      async makeAmbassador() {
        try {
          const ambassadorResult = (
            await this.$axios.post(`/ambassador/add`, {
              databaseId: this.$route.params.userId,
            })
          ).data;

          if (ambassadorResult.success) {
            await this.fetchAccount();
            this.$router.push({
              name: 'ambassador_dashboard',
            });
          } else {
            this.$notify({
              type: 'error',
              title: this.ambassadorError,
            });
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            title: this.ambassadorError,
          });
        }
      },
    },
  };
</script>

<style lang="sass">
  @import '../sass/variables/variables'
  .became-ambassador
    display: flex
    align-items: center
    .heading-1
      font-size: 3.425rem
      line-height: 1.25
    &-desc.body-text
      color: $om-gray-700
    &-after.body-text
      color: $om-gray-600
</style>
